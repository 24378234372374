import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
const LazyLoadImage = ({ src, alt, title, classNameOptional, imageSize, bgColor }) => {
  const [ref, inView] = useInView({ triggerOnce: true });
  const [isLoading, setIsLoading] = useState(true);
  const handleImageLoad = () => { setIsLoading(false); };
  const { t } = useTranslation();
  return (
    <div className={`h-100 d-flex align-items-center justify-content-center overflow-hidden ${isLoading ? 'square' : ''}  ${classNameOptional}`} style={{ backgroundColor: bgColor }} ref={ref} >
      {isLoading && (<span className='text-secondary loaderText p-2 theme-text-color mb-0 text-break position-absolute'>{t(title)} </span>)}
      <img loading="lazy" onLoad={handleImageLoad} src={inView ? src : ''} alt={alt} className={`position-relative d-block w-100 h-100 ${isLoading ? 'opacity-0 ' : 'opacity-100'}  ${imageSize}`} />
    </div>
  );
};
export default LazyLoadImage;