import React from 'react';
import BannerOverlay from './BannerOverlay';
import BannerOverlayThemeTwo from './BannerOverlayThemeTwo';
import { connect } from 'react-redux';
import { useInView } from 'react-intersection-observer';

const BannerImage = ({ data, currentTheme, page, siteTheme }) => {

    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const backgroundImageStyle = inView ? { backgroundImage: `linear-gradient(90deg, rgba(0,0,0,0.5) 50%, transparent), url(${data?.slider_image ? data?.image_url : data?.Player_image_url})` } : { backgroundColor: '' };

    return (
        <div
            loading="lazy"
            ref={ref}
            className={`${currentTheme === 1 || page === "movie" ? "bannerContainer" : "bannerWrapper"} w-100 h-100 ${data?.id} trailerNot`}
            key={data?.id}
            style={backgroundImageStyle}
        > {currentTheme == 1 && <BannerOverlay data={data} type="image" page={page} translateCheckout={siteTheme?.translate_checkout} />}
            {currentTheme == 2 && (<BannerOverlayThemeTwo data={data} type="image" page={page} translateCheckout={siteTheme?.translate_checkout} className={page == "movie" ? "px-2 px-md-3 px-lg-5 py-2 py-md-3 py-lg-5 " : ""} />)}
        </div>
    );
};

const mapStateToProps = (state) => ({
    currentTheme: state.get_siteTheme_Reducer.currentTheme,
    siteTheme: state.get_siteTheme_Reducer.siteTheme,
});
export default connect(mapStateToProps)(BannerImage);
