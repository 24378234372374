// External Libraries and Packages
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation } from "swiper/modules";
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
import LazyLoadImage from './LazyLoadImage';
import live from "../../../assets/images/live-indicater.webp";
import { useTranslation } from 'react-i18next';
import PriceLabel from '../../../components/PriceLabel';
import { useMyContext } from '../../../components/Layout/CommonProvider';
// Internal Components

const LiveStreamCategoriesAll = ({ value }) => {
    const swiper = useSwiper();
    const { t } = useTranslation();
    const { navigateTranslateChecker } = useMyContext()
    return (
        <div className='px-3 px-md-4 px-lg-4 pt-1 zIndex '>
            {value?.category_livestream?.length > 0 && (<>
                <Link to={navigateTranslateChecker(`/live-categories/${value?.slug}`)} className="blocklinetitle d-inline-block swiperHeading theme-text-color" aria-label={`View all ${value?.name}`}>{t(value?.name)}</Link>
                <Link to={navigateTranslateChecker(`/live-categories/${value?.slug}`)} className="blocklinetitle float-end swiperHeading theme-text-color  viewall-homepage " aria-label={`View all ${value?.name}`}>{t('View All')}</Link>
            </>)
            }
            <div className="">
                <Swiper slidesPerView={1}
                    spaceBetween={15}
                    navigation={true}
                    lazy={true}
                    slidesPerGroup={1}
                    breakpoints={{
                        576: {
                            slidesPerView: 2,
                            spaceBetween: 15,
                            slidesPerGroup: 2,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 15,
                            slidesPerGroup: 3,
                        },

                        1200: {
                            slidesPerView: 4,
                            spaceBetween: 15,
                            slidesPerGroup: 4,
                        }

                    }}
                    modules={[Navigation]}
                    className={`homePageSwiper livestream`}>
                    {value?.category_livestream?.map(
                        (category_livestream, index) => (
                            <SwiperSlide key={category_livestream?.id} className='theme-bg-color-secondary'>
                                <PriceLabel item={category_livestream} />
                                <Link to={navigateTranslateChecker(`/live/${category_livestream?.slug}`)} className='d-block h-100 w-100 categoryHover'>
                                    <LazyLoadImage key={index} src={category_livestream?.image_url} alt={category_livestream?.title} title={category_livestream?.title} classNameOptional="rounded-3" imageSize="homeLiveImage loaderMain object-fit-cover" />
                                    <span className="live_Indicator">
                                        <img src={live} className="" style={{ width: "65px", height: "27px" }} alt="Live_stream new" />
                                    </span>
                                </Link >

                            </SwiperSlide >
                        )
                    )}
                </Swiper >
            </div >
        </div >
    )
}

export default LiveStreamCategoriesAll