import React, { useEffect, useState } from 'react'
import { URLDashRemover } from '../../../Pages/CommonMethods/CommonMethods'
import PaginationViewAll from './PaginationViewAll'
import { connect, useDispatch } from "react-redux";
import MessageBox from '../../MessageBox';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import CardWithHover from '../../../Pages/HomePage/HomeSwiperComponents/Theme/CardWithHover';
import CardWithoutHover from '../../../Pages/HomePage/HomeSwiperComponents/Theme/CardWithoutHover';
import Select, { components } from 'react-select';
import { BsSortDownAlt } from 'react-icons/bs';
import { filterAction } from '../../../Redux/Actions/apiActions';
import axios from 'axios';
import StaticHomeSwiper from '../../../Pages/HomePage/HomeSwiperComponents/StaticHomeSwiper';
import { useMyContext } from '../../Layout/CommonProvider';
const LayoutViewAll = ({ settings, mapData, source, path, headingClass, themeMode, filter, filterSettings, categoryId }) => {
    // console.log(mapData, 'mapData');
    // console.log(source, 'source');

    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = (pageNumber) => { setCurrentPage(pageNumber); };
    const { t } = useTranslation();
    const { navigateTranslateChecker } = useMyContext()
    const [loading, setLoading] = useState(false);
    // const [mapData, setMapData] = useState(propsMapData || []);
    const [filterData, setFilterData] = useState([])
    const [selectedArtist, setSelectedArtist] = useState(null);
    const [selectedEquipment, setSelectedEquipment] = useState(null);
    const dispatch = useDispatch();
    const location = useLocation();
    const access_token = localStorage.getItem("access_token");
    const headers = {
        "Content-Type": "application/json", Authorization: "Bearer " + access_token, Accept: "application/json"
    };

    useEffect(() => {
        if (filterSettings) {
            const fetchFilterData = async () => {
                try {
                    dispatch(filterAction());
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            fetchFilterData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSettings]);

    // useEffect(() => {
    //     const sortedData = [...propsMapData].sort((a, b) => new Date(b.year) - new Date(a.year));
    //     console.log(sortedData, "sorted")
    //     setMapData(sortedData);
    // }, [propsMapData]);
    const languages = filter?.Languages || [];
    const options1 = languages?.map(language => ({
        value: language?.id,
        label: language?.name
    }));

    const equipments = filter?.Artists || [];
    const options2 = equipments?.map(value => ({
        value: value?.id,
        label: value?.artist_name
    }));
    useEffect(() => {
        const storedOption1 = localStorage.getItem('selectedOption1');
        const storedOption2 = localStorage.getItem('selectedOption2');

        if (storedOption1) {
            setSelectedEquipment(JSON.parse(storedOption1));
        }

        if (storedOption2) {
            setSelectedArtist(JSON.parse(storedOption2));
        }
    }, [location]);
    useEffect(() => {
        if (selectedArtist || selectedEquipment) {
            filterSubmit();
        }
    }, [selectedArtist, selectedEquipment, location, mapData]);

    const changeEquipment = (option) => {
        setSelectedEquipment(option);
        if (option) {
            localStorage.setItem('selectedOption1', JSON.stringify(option));
        } else {
            localStorage.removeItem('selectedOption1');
        }
    };

    const changeTrainer = (option) => {
        setSelectedArtist(option);
        if (option) {
            localStorage.setItem('selectedOption2', JSON.stringify(option));
        } else {
            localStorage.removeItem('selectedOption2');
        }
    };

    const filterSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Filter-Videos`, {
                category_id: categoryId,
                Language_id: selectedEquipment?.value,
                Artist_id: selectedArtist?.value,
            }, { headers });
            const sortedVideos = response?.data?.videos.sort((a, b) => new Date(b.year) - new Date(a.year));
            // console.log(response?.data?.videos)
            setFilterData(sortedVideos);
            // console.log(sortedVideos?.length, "sorted videos");
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };
    const colourStyles = {
        indicatorSeparator: () => ({ display: 'none' }),
        singleValue: (provided, state) => ({ ...provided, color: 'white' }),
        control: styles => ({ ...styles, backgroundColor: 'transparent', borderRadius: '10px' }),
        // menu: (provided) => ({...provided, width: 300}),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return { ...styles, backgroundColor: isDisabled ? 'red' : 'white', color: 'black', cursor: isDisabled ? 'not-allowed' : 'default' }
        },
        placeholder: (styles) => ({ ...styles, color: 'white', marginLeft: '2px', marginRight: '2px' }),
    }
    const CustomDropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <BsSortDownAlt size={20} style={{ "fill": "white" }} />
            </components.DropdownIndicator>
        );
    };
    const customComponents = { DropdownIndicator: CustomDropdownIndicator };
    return (
        <div className="px-md-5 px-2">
            {source ? <h2 className={`viewAllTitle ${headingClass ? headingClass : 'text-center'} mb-0 theme-text-color`}><MessageBox text={`${t(URLDashRemover(source))}`} /></h2> : ''}
            {filterSettings && (
                <div className='d-flex justify-content-end' style={{ position: "relative", "zIndex": "2" }}>
                    <Select value={selectedEquipment} onChange={changeEquipment} styles={colourStyles} isClearable={true}
                        options={options1} components={customComponents} placeholder="Select Equipment" className="mx-2 mb-4" />
                    <Select value={selectedArtist} onChange={changeTrainer} styles={colourStyles} isClearable={true}
                        options={options2} components={customComponents} placeholder="Select Trainer" className="mx-2 mb-4" />
                </div>
            )}
            {loading ? (
                Array.from({ length: 2 }).map((n) => <StaticHomeSwiper key={n} />)
            ) : (
                (selectedArtist !== null || selectedEquipment !== null) ? (
                    filterData?.length > 0 ? (
                        <div className="gridContainer">
                            {filterData?.slice((currentPage - 1) * settings?.videos_per_page, currentPage * settings?.videos_per_page)
                                .map((data, index) => (
                                    <div key={data?.id} className={`viewAllCardTwo position-relative theme-bg-color-secondary rounded-3 mb-4 ${Number(Math.round((index + 1) % 6)) === '1' ? '' : Number(Math.round((index + 1) % 6)) === '0' ? '' : ''}`}>
                                        {themeMode?.hoverCard ? <CardWithHover value={data} segMent={path} hoverClass={'hoverCardContainer'} /> : <CardWithoutHover value={data} segMent={path} />}
                                    </div>
                                ))}
                        </div>
                    ) : (
                        <div>No Filtered Data Available</div>
                    )
                ) : (
                    mapData?.length > 0 ? (
                        <div className="gridContainer">
                            {mapData?.slice((currentPage - 1) * settings?.videos_per_page, currentPage * settings?.videos_per_page)
                                .map((data, index) => (
                                    <div key={data?.id} className={`viewAllCardTwo position-relative theme-bg-color-secondary rounded-3 mb-4 ${Number(Math.round((index + 1) % 6)) === '1' ? '' : Number(Math.round((index + 1) % 6)) === '0' ? '' : ''}`}>
                                        {themeMode?.hoverCard ? <CardWithHover value={data} segMent={path} hoverClass={'hoverCardContainer'} /> : <CardWithoutHover value={data} segMent={path} />}
                                    </div>
                                ))}
                        </div>
                    ) : (
                        <div>No Data Available</div>
                    )
                )
            )}
            {/* <PaginationViewAll data={mapData} handlePage={handlePageChange} currentPage={currentPage} visibleLength={settings?.videos_per_page} /> */}
            <PaginationViewAll data={selectedArtist !== null || selectedEquipment !== null ? filterData : mapData} handlePage={handlePageChange} currentPage={currentPage} visibleLength={settings?.videos_per_page} />
        </div>
    )
}
const mapStateToProps = (state) => ({
    settings: state.get_setting_Reducer.settings,
    themeMode: state.get_headerIndex_Reducer.themeMode,
    filter: state.get_filter_Reducer.filter,
});
export default connect(mapStateToProps)(LayoutViewAll)