import React, { useMemo, useEffect } from "react";
import Header from "../Header/Header";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../Footer/footer";
import FloatAudioPlayer from "../../Players/FloatAudioPlayer";
import { connect } from "react-redux";
import MiniFloatPlayer from "../../Players/MiniFloatPlayer";
import ThemeTwoHeader from "../Header/ThemeTwoHeader";
import ThemeTwoFooter from "../Footer/ThemeTwoFooter";
const HomeCommonLayout = ({ getMusicData, currentClose, minimize, currentTheme }) => {
  const excludedPathsHeader = ['/trailer', '/episode', '/player/video', '/player/live'];
  const excludedPathsFooter = ['/trailer', '/episode', '/player/video', '/player/live'];
  const location = useLocation()
  const currentPath = location.pathname;
  const dynamicPathsRegex = useMemo(() => [
    /^(?:\/\w+)?\/episode\/[^/]+\/[^/]+/,
    /^(?:\/\w+)?\/music/,
    /^(?:\/\w+)?\/preference/,
    /^(?:\/\w+)?\/player\/video\/[^/]+$/,
    /^(?:\/\w+)?\/player\/live\/[^/]+$/,
    /^(?:\/\w+)?\/trailer\/[^/]+\/[^/]+/,
    /^(?:\/\w+)?\/login/,
    /^(?:\/\w+)?\/signup/,
    /^(?:\/\w+)?\/forget/,
    /^(?:\/\w+)?\/choose-profile/,
    /^(?:\/\w+)?\/multi-profile\/edit/,
    /^(?:\/\w+)?\/multi-profile\/create/,
  ], [currentPath]);
  const isPathExcluded = (excludedPaths) => {
    return excludedPaths.some((path) => currentPath.startsWith(path)) ||
      dynamicPathsRegex.some((regex) => regex.test(currentPath));
  };
  return (
    <div className="position-relative overflow-hidden">
      {currentTheme === 1 && !isPathExcluded(excludedPathsHeader) && <Header />}
      {currentTheme === 2 && !isPathExcluded(excludedPathsHeader) && <ThemeTwoHeader />}
      <Outlet />
      {getMusicData?.enable_floating && currentClose && !minimize ? <FloatAudioPlayer /> : null}
      {minimize && <MiniFloatPlayer />}
      {currentTheme === 1 && !isPathExcluded(excludedPathsFooter) && <Footer />}
      {currentTheme === 2 && !isPathExcluded(excludedPathsFooter) && <ThemeTwoFooter />}
    </div>
  );
};
const mapStateToProps = state => ({
  getMusicData: state.get_Music_Settings.getMusicData,
  currentClose: state.get_current_Reducers.currentClose,
  minimize: state.get_Playlist_Modal.minimize,
  currentTheme: state.get_siteTheme_Reducer.currentTheme,
});
export default connect(mapStateToProps)(HomeCommonLayout);