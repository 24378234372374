import React, { useRef } from 'react'
import { FcBarChart } from 'react-icons/fc'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMyContext } from '../Layout/CommonProvider';
import LazyLoadImage from '../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage'
import { IconMyPlay } from '../../assets/icons/MyIcons'

//page props ->  movie,show,home

const BannerOverlay = ({ data, type, page, translateCheckout }) => {
    const overlayRef = useRef()
    const { t } = useTranslation();
    const { navigateTranslateChecker } = useMyContext()

    const handleContextMenu = (e) => {
        e.preventDefault();
    };
    const handleKeyDown = (e) => {
        if (e.key) {
            e.preventDefault();
        }
        if (e.key === 'PrintScreen' || (e.ctrlKey && e.key === 'S')) {
            e.preventDefault();
        }
    };

    const getPathBySourceType = (sourceTypeId, data) => {
        switch (sourceTypeId) {
            case 1:
                return `/videos/${data?.slug}`;
            case 2:
                return `/live/${data?.slug}`;
            case 3:
                return `/shows/${data?.slug}`;
            case 4:
                return `/series/${data?.series_slug}/${data?.slug}`;
            case 5:
                return `/music/track/${data?.slug}`;
            default:
                return data?.link;
        }
    };

    const path = data?.source_types_id !== null ? getPathBySourceType(data.source_types_id, data) : data?.link;


    // overlayRef?.current?.addEventListener('contextmenu', handleContextMenu);
    // overlayRef?.current?.addEventListener('keydown', handleKeyDown);

    return (
        <div key={data?.id} className={`${page == "movie" ? "px-2 px-md-3 px-lg-5" : ""} bannerPopContainer active`} ref={overlayRef}>
            <div className="d-flex flex-column justify-content-around w-50 h-100 py-md-4 ">
                <LazyLoadImage loading="lazy" src={data?.TV_image_url ? data?.TV_image_url : data?.TV_Thumbnail ? data?.TV_Thumbnail : data?.slider_image ? data?.image_url : data?.Player_image_url} alt="bannerimage" imageSize="bannerLogoImage object-fit-cover overflow-hidden" classNameOptional="rounded-2 bannerLogoImage overflow-hidden" />
                <p className="theme-text-color bannerPopTitle m-0 ">{t(data?.title)}</p>
                {!data?.trailer_link && <>
                    <span className={`theme-text-color ${page == "home" ? "bannerPopDesc" : page == "movie" ? "bannerMoviePopDesc" : ""}  d-block `}>{t(data?.description || data?.episode_description)}</span>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            {/* <span className="bannerPopSpecial d-flex align-items-center "> <FcLike className="me-1 theme-text-color" /> <span className="me-2">{data?.rating}  </span> </span> */}
                            {data?.trailer_link && <><span className="bannerPopSpecial d-flex align-items-center "> <FcBarChart className="me-1 theme-text-color" /><span className="me-2">{data?.duration} </span> </span></>}
                            {/* <span className="bannerPopSpecial d-flex align-items-center "><FcBookmark className="me-1 theme-text-color" /> <span className="me-2">{data?.year} </span> </span> */}
                        </div>
                    </div>
                </>}
            </div>
            <div className="d-flex align-items-center justify-content-end bannerPlayBtn">
                <Link to={data?.source_types_id !== null ? navigateTranslateChecker(`${path}`) : path} className="homeListButton m-0 px-2 py-2 bgButton d-flex align-items-center justify-content-center bannerButton position-relative">
                    {/* {type == "video" ? <img src={Playbtn} className="homeBannerPlayImage" alt="playbutton" /> : <MdOutlineArrowOutward />} */}
                    {/* <img src={Playbtn} className="homeBannerPlayImage" alt="playbutton" /> */}
                    <IconMyPlay styled={{ width: "20px", height: "20px", className: "musicIconHome triangle" }} />
                </Link>
            </div>
        </div >
    )
}

export default BannerOverlay