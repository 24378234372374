import React, { useEffect, useState } from 'react'
import { seasonDetailsAction } from '../../../Redux/Actions/apiActions';
import { connect, useDispatch } from 'react-redux';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BiChevronDown } from "react-icons/bi";
import Select, { components } from 'react-select';
import { BsSortDownAlt } from 'react-icons/bs';
import { AiOutlineSketch } from "react-icons/ai";
import { filterAction } from '../../../Redux/Actions/apiActions';
import StaticSkeletonEpisode from '../../../Pages/HomePage/HomeSwiperComponents/StaticSkeletonEpisode';
import axios from 'axios';
import { toast } from "react-toastify";
import RentModal from './RentModal';
// import { BiPlayCircle } from "react-icons/bi";
// import { AiOutlinePlayCircle } from 'react-icons/ai';
import { AiOutlineCloseCircle } from "react-icons/ai";
// import { FaPlay } from "react-icons/fa6";
// import { FaRegCirclePlay } from "react-icons/fa6";
// import { MdVerified } from "react-icons/md";
// import { VscVerifiedFilled } from "react-icons/vsc";
import { FaCirclePlay } from "react-icons/fa6";
import LazyLoadImage from '../../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage';
import usePpvGlobalImageConfig from "../../Api/Rentppv_global_change";
import { useMyContext } from '../../Layout/CommonProvider';

const SingleSeasonDetails = ({ seriesDetailsData, settings, seasonDetailsData, seasonId, props, error, seasonNumber, filter, profileDetails }) => {
  const filterSettings = true;
  const SeriesSeason_detailsSeriesSeason_details = seasonDetailsData?.SeriesSeason_details?.[0];
  const [loading, setLoading] = useState(true);
  const [visibleEpisodes, setVisibleEpisodes] = useState(10);
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const access_token = localStorage.getItem("access_token");
  const [episodes, setEpisodes] = useState([])
  // const [domReload, setDomReload] = useState(false)
  // const [language_id, setLanguageId] = useState('');
  // const [artists_id, setArtistsId] = useState('');
  // const [category_id, setCategoryId] = useState('');
  const [selectedTrainer, setSelectedTrainer] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [seasonImage, setSeasonImage] = useState('');
  const [access, setAccess] = useState('');
  const [showModal, setShowModal] = useState(false);

  const headers = { "Content-Type": "application/json", Authorization: "Bearer" + access_token, Accept: "application/json" };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { lang } = useParams()
  const { navigateTranslateChecker } = useMyContext()
  const location = useLocation();
  useEffect(() => {
    if (selectedTrainer || selectedCategory || selectedEquipment) {
      // If any of the filters are selected, call filterSubmit
      filterSubmit();
    }
    else if (settings && Object.keys(settings).length > 0) {
      !user_role && settings.access_free === 1
        ? dispatch(seasonDetailsAction(null, 9, "web", seriesDetailsData?.id, seasonId, headers))
        : user_role && dispatch(seasonDetailsAction(user_id, user_role, "web", seriesDetailsData?.id, seasonId, headers));
    }
  }, [location, user_role, selectedCategory, selectedEquipment, selectedTrainer])
  useEffect(() => {
    if (seasonDetailsData?.SeriesSeason_details) {
      try {
        const lastSeasonIndex = seasonDetailsData?.SeriesSeason_details.length - 1;
        const lastSeasonEpisodes = seasonDetailsData?.SeriesSeason_details[lastSeasonIndex].series_season_episode;
        const Episodes = lastSeasonEpisodes.slice();
        setEpisodes(Episodes);
        const timer = setTimeout(() => {
          setLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
      } catch (err) {
        setLoading(false);
      }
    }
  }, [seasonDetailsData, seriesDetailsData, location, selectedTrainer, selectedCategory, selectedEquipment]);

  const generateEpisodeNumbers = (episodes) => {
    const reversedEpisodes = [...episodes];
    return reversedEpisodes.map((episode, index) => ({
      ...episode,
      episodeNo: `E${reversedEpisodes.length - index}`
    }));
  };
  const formatDurationFormat = (duration) => {
    if (!duration) return '';
    const parts = duration.split(':');
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}H ${formattedMinutes}M`;
  };
  const episodesWithNumbers = generateEpisodeNumbers(episodes);
  const episodesToShow = episodesWithNumbers?.slice(0, visibleEpisodes);
  // console.log(episodesToShow, "show")
  useEffect(() => {
    if (filterSettings) {
      const fetchFilterData = async () => {
        try {
          dispatch(filterAction());
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchFilterData();
    }
  }, [filterSettings]);
  const languages = filter?.Languages || [];
  const options1 = languages?.map(language => ({
    value: language.id,
    label: language.name
  }));

  const categories = filter?.Categories || [];
  const options2 = categories?.map(value => ({
    value: value.id,
    label: value.name
  }));

  const equipments = filter?.Artists || [];
  const options3 = equipments?.map(value => ({
    value: value.id,
    label: value.artist_name
  }));

  useEffect(() => {
    const savedTrainer = JSON.parse(localStorage.getItem('selectedTrainer'));
    const savedCategory = JSON.parse(localStorage.getItem('selectedCategory'));
    const savedEquipment = JSON.parse(localStorage.getItem('selectedEquipment'));
    if (savedTrainer) setSelectedTrainer(savedTrainer);
    if (savedCategory) setSelectedCategory(savedCategory);
    if (savedEquipment) setSelectedEquipment(savedEquipment);
  }, []);

  const handleChange1 = (option) => {
    setSelectedTrainer(option);
    if (option) {
      localStorage.setItem('selectedTrainer', JSON.stringify(option));
    } else {
      localStorage.removeItem('selectedTrainer');
    }
  };

  const handleChange2 = (option) => {
    setSelectedCategory(option);
    if (option) {
      localStorage.setItem('selectedCategory', JSON.stringify(option));
    } else {
      localStorage.removeItem('selectedCategory');
    }
  };

  const handleChange3 = (option) => {
    setSelectedEquipment(option);
    if (option) {
      localStorage.setItem('selectedEquipment', JSON.stringify(option));
    } else {
      localStorage.removeItem('selectedEquipment');
    }
  };

  const filterSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Filter-Season-Episodes`, {
        Language_id: selectedEquipment?.value,
        Artist_id: selectedTrainer?.value,
        Category_id: selectedCategory?.value,
        season_id: seasonId
      }, headers);
      const fetchedEpisodes = response.data.Episodes;
      const episodesWithNumbers = generateEpisodeNumbers(fetchedEpisodes);
      setEpisodes(episodesWithNumbers);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // console.log(episodes,'episodes')

  // useEffect(() => {
  //   if (selectedTrainer || selectedCategory || selectedEquipment) {
  //     filterSubmit();
  //   }
  // }, [selectedTrainer, selectedCategory, selectedEquipment,location]);

  // useEffect(() => {
  //   if (selectedTrainer || selectedCategory || selectedEquipment) {
  //     // If any of the filters are selected, call filterSubmit
  //     filterSubmit();
  //   }
  // }, [selectedTrainer, selectedCategory, selectedEquipment, location]);

  const handleViewMore = () => {
    setVisibleEpisodes(prevCount => prevCount + 10);
  };
  const seasonData = seasonDetailsData?.SeriesSeason_details;
  useEffect(() => {
    if (seasonDetailsData?.SeriesSeason_details) {
      const seasonData = seasonDetailsData?.SeriesSeason_details;
      const image = seasonData[0]?.Thumbnail;
      const access1 = seasonData[0]?.access;
      if (image) {
        setSeasonImage(image);
      } if (access1) {
        setAccess(access1)
      }
    }
  }, [seasonDetailsData]);
  const colourStyles = {
    indicatorSeparator: () => ({ display: 'none' }),
    singleValue: (provided, state) => ({ ...provided, color: 'white' }),
    control: styles => ({ ...styles, backgroundColor: 'transparent', borderRadius: '10px' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return { ...styles, backgroundColor: isDisabled ? 'red' : 'white', color: 'black', cursor: isDisabled ? 'not-allowed' : 'default' }
    },
    placeholder: (styles) => ({ ...styles, color: 'white', marginLeft: '2px', marginRight: '2px' })
  }
  const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}><BsSortDownAlt size={20} style={{ "fill": "white" }} /></components.DropdownIndicator>
    );
  };
  const customComponents = { DropdownIndicator: CustomDropdownIndicator };
  //
  const { PPV_exists } = seasonDetailsData;
  //console.log(PPV_exists)

  // const handleSeasonClick = async (value) => {
  //   if (seasonData && seasonData.length > 0) {
  //     const seasonAccess = seasonData[0]?.access;
  //     if (seasonAccess == 'ppv' && PPV_exists?.Series_PPV_exists == false && PPV_exists?.SeriesSeason_PPV_exists == false) {

  //       if (
  //         !user_role ||
  //         user_role == 2 && seasonAccess == 'subscriber' ||
  //         user_role == 3 ||
  //         (PPV_exists?.Series_PPV_exists == false && PPV_exists?.SeriesSeason_PPV_exists == false)
  //       ) {
  //         handleShowModal(true);
  //       }
  //     } else if (
  //       seasonAccess == 'subscriber' ||
  //       seasonAccess == 'registered' ||
  //       seasonAccess == 'guest' ||
  //       user_role == 1 ||
  //       (user_role == 2 && PPV_exists?.Series_PPV_exists == true && PPV_exists?.SeriesSeason_PPV_exists == true) ||
  //       (user_role == 3 && PPV_exists?.Series_PPV_exists == true && PPV_exists?.SeriesSeason_PPV_exists == true)
  //     ) {
  //       // Navigate to the series if conditions are met
  //       //navigate(navigateTranslateChecker(`/series/${seriesDetailsData?.slug}/${value?.slug}`));
  //     }
  //   } else {
  //     console.log('No season data available or empty array');
  //   }
  // };

  const handleSeasonClick = async (value) => {
    if (seasonData && seasonData.length > 0) {
      const seasonAccess = seasonData[0]?.access;

      // Open modal if the user role is 2 and season access is "subscriber"
      if (user_role == 2 && seasonAccess == 'subscriber'
        || !user_role && seasonAccess == 'subscriber') {
        handleShowModal(true);
        return; // Exit early since the modal is shown
      }

      // Handle PPV access conditions
      if (seasonAccess == 'ppv') {
        if (
          PPV_exists?.Series_PPV_exists == false &&
          PPV_exists?.SeriesSeason_PPV_exists == false &&
          (!user_role || user_role == 3 || user_role == 2) || PPV_exists?.Series_PPV_exists == true &&
          PPV_exists?.SeriesSeason_PPV_exists == false &&
          (!user_role || user_role == 3 || user_role == 2)
        ) {
          handleShowModal(true);
          return; // Exit early since the modal is shown
        }
      }

      // Handle navigation if user has appropriate access
      if (
        seasonAccess == 'subscriber' ||
        seasonAccess == 'registered' ||
        seasonAccess == 'guest' ||
        user_role == 1 ||
        (user_role == 2 && PPV_exists?.Series_PPV_exists == true && PPV_exists?.SeriesSeason_PPV_exists == true) ||
        (user_role == 3 && PPV_exists?.Series_PPV_exists == true && PPV_exists?.SeriesSeason_PPV_exists == true)
      ) {
        // Navigate to the series if conditions are met
        navigate(navigateTranslateChecker(`/series/${seriesDetailsData?.slug}/${value?.slug}`));
      }
    } else {
      console.log('No season data available or empty array');
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showModal]);

  //   geo ip location integration 
  const { currency, currencySymbol, userCurrency, locationResponselocationResponse } = usePpvGlobalImageConfig();
  const [convertedPrice, setConvertedPrice] = useState(null);

  useEffect(() => {
    const fetchConversionRate = async () => {
      if (!userCurrency || !currency?.code || currency?.enable_multi_currency !== 1) return;
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${currency?.code}`);
        const data = response.data;
        const conversionRate = data.rates[userCurrency];
        if (conversionRate) {
          const convertedPPVPrice = SeriesSeason_detailsSeriesSeason_details?.ppv_price * conversionRate;
          setConvertedPrice(convertedPPVPrice);
        } else {
          console.error('Conversion rate not found for currency:', userCurrency);
        }
      } catch (error) {
        console.error('Error fetching conversion rate:', error);
      } finally {
      }
    };

    fetchConversionRate();
  }, [userCurrency, currency?.code, currency?.enable_multi_currency, SeriesSeason_detailsSeriesSeason_details?.ppv_price]);

  //   geo ip location integration 
  //   geo ip location integration 

  //    Iyzico payment integration 
  //    Iyzico payment integration 
  const successpage = window.location.href;
  const ppv_priceppv_price = SeriesSeason_detailsSeriesSeason_details?.ppv_price
  const [formDataiyzicopayment, setFormDataiyzicopayment] = useState({
    locale: '',
    conversationId: '',
    price: ppv_priceppv_price, // Initialize with an empty value
    paidPrice: ppv_priceppv_price, // Initialize with an empty value
    successpage: successpage, // Initialize with an empty value
    currency: '',
    basketId: '',
    paymentGroup: 'PRODUCT',
    callbackUrl: '',
    enabledInstallments: [2, 3, 6, 9],
    buyer: {
      id: '',
      name: '',
      surname: '',
      gsmNumber: '',
      email: '',
      identityNumber: '',
      lastLoginDate: '',
      registrationDate: '',
      registrationAddress: '',
      ip: '',
      city: '',
      country: '',
      zipCode: ''
    },
    shippingAddress: {
      contactName: 'Jane Doe',
      city: 'Istanbul',
      country: 'Turkey',
      address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
      zipCode: '34742'
    },
    billingAddress: {
      contactName: 'Jane Doe',
      city: 'Istanbul',
      country: 'Turkey',
      address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
      zipCode: '34742'
    },
    basketItems: [
      {
        id: 'BI101',
        name: 'Binocular',
        category1: 'Collectibles',
        category2: 'Accessories',
        itemType: 'PHYSICAL',
        price: ppv_priceppv_price,
      }
    ]
  });


  useEffect(() => {
    let value;
    if (currency?.code === null || currency?.code === "") {
      value = SeriesSeason_detailsSeriesSeason_details?.ppv_price !== null ? SeriesSeason_detailsSeriesSeason_details?.ppv_price : '';
    } else {
      if (settings?.PPV_Individual_Contents === "0" || settings?.PPV_Individual_Contents === 0) {
        value = convertedPrice !== null ? convertedPrice : '';
      } else {
        value = SeriesSeason_detailsSeriesSeason_details?.ContentPriceRate !== null
          ? SeriesSeason_detailsSeriesSeason_details?.ContentPriceRate?.ChangedPrice
          : convertedPrice !== null
            ? convertedPrice
            : '';
      }
    }

    const getCurrentDateTime = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const currentDateTime = getCurrentDateTime();
    const locale = lang === "tr" ? "tr" : "en";
    const conversationId = user_id;
    const currencyfinal = currency?.code;
    const basketId = profileDetails?.Profile_details?.id;
    const gsmNumber = "+" + profileDetails?.Profile_details?.payment_mobile_number;
    // const successpage = window.location.href;
    const failerpage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/failerpage";
    const successpage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/successpage";
    const pageredirectionslug = `${process.env.REACT_APP_API_PATHss}/` + lang + "/" + "shows" + "/" + seriesDetailsData?.slug;
    const callbackUrl = `${process.env.REACT_APP_API_IYZICO_PAYMENT}/callback`;
    const usernames = profileDetails?.Profile_details?.username;
    const last_name = profileDetails?.Profile_details?.last_name;
    const payment_mobile_number = "+" + profileDetails?.Profile_details?.payment_mobile_number;
    const user_ids = user_id;
    const emailemail = profileDetails?.Profile_details?.email;
    const city = locationResponselocationResponse?.city;
    const country = locationResponselocationResponse?.country;
    const zipCode = locationResponselocationResponse?.asn;
    const ip = locationResponselocationResponse?.ip;
    const basketItemsname = SeriesSeason_detailsSeriesSeason_details?.title || SeriesSeason_detailsSeriesSeason_details?.name;
    setFormDataiyzicopayment(prevState => ({
      ...prevState,
      locale: locale,
      price: ppv_priceppv_price,
      conversationId: conversationId,
      Login_user_id: user_id,
      Login_device_type: "web",
      source_type: 6,
      source_id: SeriesSeason_detailsSeriesSeason_details?.id,
      regionname: country,
      countryname: country,
      cityname: city,
      successpage: successpage,
      pageredirectionslug: pageredirectionslug,
      failerpage: failerpage,
      paidPrice: ppv_priceppv_price,
      currency: currencyfinal,
      basketId: basketId,
      callbackUrl: callbackUrl,
      buyer: {
        id: user_ids,
        name: usernames,
        surname: last_name,
        gsmNumber: payment_mobile_number,
        email: emailemail,
        identityNumber: gsmNumber,
        lastLoginDate: currentDateTime,
        registrationDate: currentDateTime,
        registrationAddress: country,
        ip: ip,
        city: city,
        country: country,
        zipCode: zipCode
      },
      shippingAddress: {
        contactName: usernames,
        city: city,
        country: country,
        district: city,
        address: country,
        zipCode: zipCode
      },
      billingAddress: {
        contactName: usernames,
        city: city,
        country: country,
        address: country,
        zipCode: zipCode
      },
      basketItems: [
        {
          id: user_ids,
          name: basketItemsname,
          category1: 'Videos',
          itemType: 'PHYSICAL',
          price: ppv_priceppv_price
        }
      ]
    }));
  }, [SeriesSeason_detailsSeriesSeason_details, currency, settings, convertedPrice]);

  const initializeCheckout = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_IYZICO_PAYMENT}/initialize-checkout`, formDataiyzicopayment);
      if (response.data.status === "success") {
        const paymentPageUrl = response?.data?.paymentPageUrl;
        window.location.href = paymentPageUrl;
      }
      else if (response.data.status === "failure") {
        toast.error(`${response.data.errorMessage}`)
      }
    } catch (error) {
      console.error('Error initializing checkout:', error);
    }
  };

  // useEffect(() => {
  //     let showAlert = true;

  //     const retrievePaymentDetails = async () => {
  //         try {
  //             const response = await axios.get(`http://localhost:7004/retrieve-payment-details`);

  //             if (response.data.status === 'success' && showAlert) {
  //                 try {
  //                     const locationResponse = await axios.get(`${process.env.REACT_APP_API_IP_Location}`);
  //                     const country = locationResponse?.data?.country;
  //                     const country_code = locationResponse?.data?.country_code;
  //                     const city = locationResponse?.data?.city; // Assuming you need city here

  //                     if (city && country) { // Ensure both city and country are defined
  //                         showAlert = false;

  //                         const result = response.data;
  //                         const user_id = localStorage.getItem("user_id");
  //                         const web = "web";
  //                         const SeriesSeason_detailsSeriesSeason_detailsid = SeriesSeason_detailsSeriesSeason_details?.id;

  //                         const formDataiyzico = new FormData();
  //                         formDataiyzico.append("Login_user_id", user_id);
  //                         formDataiyzico.append("Login_device_type", web);
  //                         formDataiyzico.append("source_type", 1);
  //                         formDataiyzico.append("source_id", SeriesSeason_detailsSeriesSeason_detailsid);
  //                         // formDataiyzico.append("tokenid ", result.token);
  //                         formDataiyzico.append("payment_id", result.paymentId);
  //                         formDataiyzico.append("regionname", country);
  //                         formDataiyzico.append("countryname", country);
  //                         formDataiyzico.append("cityname", city);

  //                         console.log("FormDataiyzico:", Array.from(formDataiyzico.entries())); // Log the FormData content

  //                         const postResponse = await axios.post(
  //                             `${process.env.REACT_APP_Baseurl}/Front-End/iyzico-ppv-verify-store`,
  //                             formDataiyzico
  //                         );

  //                         if (postResponse.data.status_code === 200) {
  //                             toast.success(`Purchased video was Created Successfully`);
  //                         } else {
  //                             toast.error(`Purchased video was Created Failed`);
  //                         }
  //                     } else {
  //                         console.error('City or country is undefined');
  //                     }
  //                 } catch (locationError) {
  //                     console.error('Error retrieving location data:', locationError);
  //                 }
  //             }
  //         } catch (paymentError) {
  //             console.error('Error retrieving payment details:', paymentError);
  //         }
  //     };

  //     retrievePaymentDetails();
  // }, [SeriesSeason_detailsSeriesSeason_details]); // Add `SeriesSeason_detailsSeriesSeason_details` as a dependency if it's used in the effect


  // Iyzico payment integration 
  // Iyzico payment integration 

  const [paymentGateways, setPaymentGateways] = useState([]);
  const [activeGateway, setActiveGateway] = useState(null); // Initialize activeGateway as null
  const fetchPaymentGateways = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Front-End/Payment-Gateway-list`,
        { headers: headers }
      );
      const paymentGateways = response?.data?.Payment_Gateways;
      setPaymentGateways(paymentGateways);
      // Set the active gateway to the first one by default
      setActiveGateway(paymentGateways[0]?.Payment_Gateway_name || null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGatewayClick = (gatewayName) => {
    setActiveGateway(gatewayName);
  };


  useEffect(() => {
    // fetchOptions();
    fetchPaymentGateways();
  }, []);


  const staticContentseason = {
    Stripe: (
      <>
        <div>
          <button className='btn w-100'>Stripe Pay to the {SeriesSeason_detailsSeriesSeason_details?.title ? SeriesSeason_detailsSeriesSeason_details.title : SeriesSeason_detailsSeriesSeason_details?.name}</button>
        </div>
      </>
    ),
    PayPal: (
      <>
        <div>
          <button className='btn w-100'>PayPal Pay to the {SeriesSeason_detailsSeriesSeason_details?.title ? SeriesSeason_detailsSeriesSeason_details.title : SeriesSeason_detailsSeriesSeason_details?.name}</button>
        </div>
      </>
    ),
    Razorpay: (
      <>
        <div>
          <button className='btn w-100'>Razorpay Pay to the {SeriesSeason_detailsSeriesSeason_details?.title ? SeriesSeason_detailsSeriesSeason_details.title : SeriesSeason_detailsSeriesSeason_details?.name}</button>
        </div>
      </>
    ),
    Iyzico: (
      <>
        <div>
          <button className='btn w-100' onClick={() => initializeCheckout()}>Iyzico Pay to the {SeriesSeason_detailsSeriesSeason_details?.title ? SeriesSeason_detailsSeriesSeason_details.title : SeriesSeason_detailsSeriesSeason_details?.name}</button>
        </div>
      </>
    ),
  };
  // console.log(seriesDetailsData?.access, "seriesAccess")
  // console.log(access, "seasonAccess")
  // console.log(access == "subscriber" && !user_role, "access status")
  // Iyzicopayment integration 
  // Iyzicopayment integration 

  console.log(SeriesSeason_detailsSeriesSeason_details, "SeriesSeason_detailsSeriesSeason_details?.ContentPriceRate?.Symbol")
  return (
    <>
      {/* { PPV Payment Modal} */}
      {/* { PPV Payment Modal} */}
      <div className="modal fade " id="staticBackdropseriesseason" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabelLabelSeriesSeason" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content theme-bg-color">
            <div className="modal-header">
              <h5 className="modal-title theme-text-color" id="staticBackdropLabelLabelSeriesSeason">Upgrade to <span>{SeriesSeason_detailsSeriesSeason_details?.title ? SeriesSeason_detailsSeriesSeason_details.title : SeriesSeason_detailsSeriesSeason_details?.name}</span> video pack by just paying the difference </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body rent_video_page_ul_li">
              <div className="row">
                {/* <div className="col-3 col-md-3 col-lg-3"></div> */}

                <div className='row col-lg-12'>
                  <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                    <img alt={SeriesSeason_detailsSeriesSeason_details?.title ? SeriesSeason_detailsSeriesSeason_details.title : SeriesSeason_detailsSeriesSeason_details?.name} src={SeriesSeason_detailsSeriesSeason_details?.Thumbnail} className='w-100 h-100' />
                  </div>
                  <div className='col-lg-7 col-md-7 col-sm-7 col-7 rounded-3 border-3'>
                    <div className='p-2'>
                      <p className='fw-bold fs-5  theme-text-color'>BUY {SeriesSeason_detailsSeriesSeason_details?.title ? SeriesSeason_detailsSeriesSeason_details.title : SeriesSeason_detailsSeriesSeason_details?.name}</p>
                      <hr></hr>
                      <div className='mt-4'>
                        <div>
                          <img alt={SeriesSeason_detailsSeriesSeason_details?.title ? SeriesSeason_detailsSeriesSeason_details.title : SeriesSeason_detailsSeriesSeason_details?.name} src={SeriesSeason_detailsSeriesSeason_details?.Player_thumbnail} className='w-50' />
                        </div>
                        <div className='mt-3'>
                          <ul>
                            <li className=' theme-text-color'>
                              Available to watch for <span className='fw-bold'>30 Days</span> after renting
                            </li>
                            <li className=' theme-text-color'>
                              Finish within <span className='fw-bold'>48 Hours</span> once started streaming
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr></hr>
                      <div className='mt-2'>
                        <ul className="nav nav-pills pt-3" id="pills-tab" role="tablist">
                          {paymentGateways?.map((gateway, index) => (
                            <li
                              className="nav-item d-flex align-items-center ms-2 paymenttabheading"
                              role="presentation"
                              key={index}
                            >
                              <input
                                type="radio"
                                className="nav-link"
                                id={`pills-${gateway?.Payment_Gateway_name}-tab`}
                                name="tab-radio"
                                value={gateway?.Payment_Gateway_name}
                                checked={activeGateway == gateway?.Payment_Gateway_name}
                                onChange={() =>
                                  handleGatewayClick(gateway?.Payment_Gateway_name)
                                }
                              />
                              <label
                                className="ms-2 theme-text-color"
                                htmlFor={`pills-${gateway?.Payment_Gateway_name}-tab`}
                              >
                                {gateway?.Payment_Gateway_name}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className='mt-2'>
                        <div className="row">
                          <div className="col-12 col-md-12 col-lg-12 offset-md-0">
                            <div className="tab-content text-start" id="pills-tabContent">
                              {paymentGateways?.map((gateway, index) => (
                                <div
                                  className={`tab-pane theme-text-color ${activeGateway == gateway?.Payment_Gateway_name
                                    ? "show active"
                                    : "fade"
                                    }`}
                                  id={`pills-${gateway?.Payment_Gateway_name}`}
                                  role="tabpanel"
                                  key={index}
                                >
                                  <p className="theme-text-color">{staticContentseason[gateway?.Payment_Gateway_name]}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* { PPV Payment Modal} */}
      {/* { PPV Payment Modal} */}

      <RentModal show={showModal} handleClose={handleCloseModal}>
        <div className="row w-100" >
          <div className="col-sm-4 col-lg-5 col-md-4 d-flex justify-content-around">
            <LazyLoadImage
              imageSize="loaderMain object-fit-cover"
              classNameOptional="rounded-3 w-100 livepurchase"
              loading="lazy"
              src={seasonImage}
              alt={'test'} />
          </div>
          <div className="col-sm-8 col-lg-7 col-md-8 ">
            <div className='d-flex justify-content-between'>
              {
                (user_role == 2 && access == "ppv" && seriesDetailsData?.access == "ppv" && PPV_exists?.Series_PPV_exists == false && PPV_exists?.SeriesSeason_PPV_exists == false)
                  || (user_role == 3 && access == "ppv" && seriesDetailsData?.access == "ppv" && PPV_exists?.Series_PPV_exists == false && PPV_exists?.SeriesSeason_PPV_exists == false)
                  ? <h3 className="midHeadingText mb-2 pt-5 text-break">{t("You need to buy the series first to access the seasons and episodes.")}</h3>
                  : (user_role == 2 && access == "ppv" && (seriesDetailsData?.access == "guest" || seriesDetailsData?.access == "registered") && PPV_exists?.SeriesSeason_PPV_exists == false)
                    || (user_role == 3 && access == "ppv" && (seriesDetailsData?.access == "guest" || seriesDetailsData?.access == "registered") && PPV_exists?.SeriesSeason_PPV_exists == false)
                    ? <div>
                      <h3 className="midHeadingText mb-2 mt-3 text-break">{t("You need to purchase the season to watch the episodes.")} </h3>
                      <div className='d-flex justify-content-center gap-2'>
                        <>
                          {currency?.enable_multi_currency === 1 || currency?.enable_multi_currency === "1" ? (
                            <button
                              className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdropseriesseason"
                            >
                              {convertedPrice !== null
                                ? `Rent Now ${currencySymbol} ${convertedPrice.toFixed(2)}`
                                : `Rent Now ${SeriesSeason_detailsSeriesSeason_details?.ContentPriceRate?.Symbol} ${parseFloat(SeriesSeason_detailsSeriesSeason_details?.ContentPriceRate?.ChangedPrice).toFixed(2)}`}
                            </button>
                          ) : (
                            <button
                              className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdropseriesseason"
                            >
                              {t(`Rent Now ${currency?.symbol} ${parseFloat(SeriesSeason_detailsSeriesSeason_details?.ppv_price).toFixed(2)}`)}
                            </button>

                          )}
                        </>


                        {
                          user_role == 2
                            ? <Link to={navigateTranslateChecker(`/plan`)}><button className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block" type='button'>
                              {t("Subscribe To Entire Library")}
                            </button></Link>
                            : null
                        }

                      </div>
                    </div>
                    : (access == "subscriber" && user_role == 2 && seriesDetailsData?.access == "subscriber") ||
                      (access == "ppv" && user_role == 2 && seriesDetailsData?.access == "subscriber")

                      ? <div>
                        <h3 className="midHeadingText mb-2 mt-3 text-break">{t("You need to subscribe to access the entire library.")}</h3>
                        <div className='d-flex justify-content-center'>
                          <Link to={navigateTranslateChecker(`/plan`)}><button className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block" type='button'>
                            {t("Subscribe To Entire Library")}
                          </button></Link>
                        </div>
                      </div>
                      : (access == "subscriber" && user_role == 2)
                        ? <div>
                          <h3 className="midHeadingText mb-2 mt-3 text-break">{t("You need to purchase the season to watch the episodes.")}</h3>
                          <div className='d-flex justify-content-center'>
                            <Link to={navigateTranslateChecker(`/plan`)}><button className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block" type='button'>
                              {t("Subscribe To Entire Library")}
                            </button></Link>
                          </div>
                        </div>
                        : (!user_role && access == "ppv" && seriesDetailsData?.access == "guest")
                          || (!user_role && access == "ppv" && seriesDetailsData?.access == "ppv")

                          ? <div>
                            <h3 className="midHeadingText mb-2 pt-3 mt-4 text-break">{t("Register and watch now the episodes!")}</h3>
                            <div className='d-flex justify-content-center pt-2'>
                              <Link to={navigateTranslateChecker(`/plan`)}><button className="theme-button-bg-color px-3 py-3 rounded-2 d-inline-block" type='button'>
                                {t("Register Now")}
                              </button></Link>
                            </div>
                          </div>
                          : null
              }
              <div className='mt-3 ms-3'>
                <AiOutlineCloseCircle style={{ "fontSize": "28px", "cursor": "pointer" }}
                  onClick={handleCloseModal} />
              </div>
            </div>
          </div>
        </div>
      </RentModal>
      <>
        {error ? <div>Error</div> : loading ?
          <div>{Array.from({ length: 5 }).map((_, index) => (
            <StaticSkeletonEpisode key={index} />))}</div>
          : <div className="py-3">{episodes?.length == 0 ? (
            <>
              <div className="no-episodes-message">No episodes in this season</div>
              <div className="d-flex flex-wrap justify-content-end">
                <Select value={selectedEquipment} onChange={handleChange3} styles={colourStyles} options={options1} components={customComponents} placeholder={t("Equipment")} className="mx-1 mb-2" isClearable={true} />
                <Select value={selectedCategory} onChange={handleChange2} styles={colourStyles} options={options2} components={customComponents} placeholder={t("Category")} className='mx-2 mb-2' isClearable={true} />
                <Select value={selectedTrainer} onChange={handleChange1} styles={colourStyles} options={options3} components={customComponents} placeholder={t("Trainer")} className='mx-2 mb-2' isClearable={true} />
                {/* <button style={{ width: "100px" }} className="theme-button-bg-color mx-2 mb-4 rounded-2 d-inline-block" onClick={filterSubmit}>Filter</button> */}
              </div>
            </>
          ) :
            <div>
              <div className="d-flex flex-wrap justify-content-end">
                <Select value={selectedEquipment} onChange={handleChange3} styles={colourStyles} options={options1} components={customComponents} placeholder={t("Equipment")} className="mx-1 mb-2" isClearable={true} />
                <Select value={selectedCategory} onChange={handleChange2} styles={colourStyles} options={options2} components={customComponents} placeholder={t("Category")} className='mx-2 mb-2' isClearable={true} />
                <Select value={selectedTrainer} onChange={handleChange1} styles={colourStyles} options={options3} components={customComponents} placeholder={t("Trainer")} className='mx-2 mb-2' isClearable={true} />
                {/* <button style={{ width: "100px" }} className="theme-button-bg-color mx-2 mb-4 rounded-2 d-inline-block" onClick={filterSubmit}>Filter</button> */}
              </div>
              {episodesToShow?.map((item) => (
                <div
                  key={item?.id}
                  className="row row-custom my-4 align-items-center episodeContainer"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSeasonClick(item)}
                >
                  <div className="col-12 col-lg-3 mb-2 mb-lg-0">
                    <div className="episodeImageContainer position-relative">
                      <img
                        src={item?.Player_thumbnail ? item?.Player_thumbnail : item?.Player_image_url}
                        className="loaderMain episodeImageD w-100 p-3"
                        alt={`Player ${item?.id}`} />
                      <div className="playIconContainer"><FaCirclePlay className="playIcon" /></div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-9 d-flex flex-column justify-content-start p-3"
                    style={{ borderRadius: '8px', minHeight: '165px' }}>
                    <Link className="text-decoration-none theme-text-color d-block" onClick={() => handleSeasonClick(item)}>
                      {access == 'ppv' && user_role == 2 && PPV_exists?.Series_PPV_exists == true && PPV_exists?.SeriesSeason_PPV_exists == false
                        || access == 'ppv' && user_role == 2 && PPV_exists?.Series_PPV_exists == false && PPV_exists?.SeriesSeason_PPV_exists == false
                        || access == 'ppv' && user_role == 3 && PPV_exists?.Series_PPV_exists == true && PPV_exists?.SeriesSeason_PPV_exists == false
                        || access == 'ppv' && user_role == 3 && PPV_exists?.Series_PPV_exists == false && PPV_exists?.SeriesSeason_PPV_exists == false
                        || access == 'ppv' && !user_role && PPV_exists?.Series_PPV_exists == false && PPV_exists?.SeriesSeason_PPV_exists == false ? (
                        <button className="rounded-1 p-1">
                          <span style={{ fontSize: '14px' }}>{t('Rent')}</span>
                          <AiOutlineSketch style={{ width: '22px', height: '22px' }} className="" />
                        </button>
                      )
                        : access == 'subscriber' && user_role == 2 || access == 'subscriber' && !user_role
                          ? (
                            <button className="rounded-1 p-1">
                              <span style={{ fontSize: '14px' }}>{t('Subscribe To Watch')}</span>
                            </button>
                          )
                          : user_role == 1 ? null : null}
                      <h3 className="episodeHeading">{t(item?.title)}</h3>
                      <div className="d-flex gap-2 my-2">
                        <div className="overview">
                          <label className="smallText1">{`${seasonNumber} ${item?.episodeNo}`}</label>
                        </div>
                        <div className="overview">
                          <span className="dot me-2"></span>
                          <label className="smallText1">{`${item?.year}`}</label>
                        </div>
                        <div className="overview">
                          <span className="dot me-2"></span>
                          <label className="smallText1">{formatDurationFormat(item?.duration)}</label>
                        </div>
                      </div>
                      <div dangerouslySetInnerHTML={{
                        __html: item?.episode_description && item.episode_description.length > 100
                          ? item.episode_description.substring(0, 100) + '...'
                          : item.episode_description || ''
                      }} />
                    </Link>
                  </div>
                </div>
              ))}

            </div>}
            {visibleEpisodes < episodesWithNumbers?.length && (<div className='d-flex justify-content-center'>
              <button className='p-3 rounded-2 viewEpisode' onClick={handleViewMore}><BiChevronDown style={{ fontSize: '30px' }} /> {t("View More")}  </button>
            </div>)}</div>}
      </>
    </>
  )
}
const mapStateToProps = (state, ownProps) => ({
  props: ownProps,
  seasonDetailsData: state.get_seasonDetails_Reducer.seasonDetailsData,
  isLoadingapi: state.get_seasonDetails_Reducer.isLoadingapi,
  error: state.get_seasonDetails_Reducer.error,
  settings: state.get_setting_Reducer.settings,
  filter: state.get_filter_Reducer.filter,
  profileDetails: state.get_profileDetails_Reducer.profileDetails,
});
export default connect(mapStateToProps)(SingleSeasonDetails)