// External Libraries and Packages
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { BiError } from 'react-icons/bi';
import { BsDownload } from "react-icons/bs";
// Internal Styles
import { toast } from "react-toastify";
import "../../../Pages/HomePage/Video_details.css"
import axios from 'axios';
import "../../../Styles/Details.css"
// Internal Components
import { videoDetailsAction } from '../../../Redux/Actions/apiActions';
import Error404 from '../../Error404';
import HelmetDetails from '../../HelmetDetails';
import Breadcrumb from '../../Breadcrumb';
import { LikeAction, disLikeAction, watchLaterAction, wishListAction, } from '../../../Pages/CommonMethods/CommonMethods';
import SocialMediaShareBox from "../../SocialMediaShareBox";
import { MdOutlineWatchLater, MdWatchLater } from "react-icons/md";
import { FiThumbsUp, FiThumbsDown, } from "react-icons/fi";
import { FaShareAlt } from "react-icons/fa";
import ToolTip from '../../ToolTip';
import ReelsMainPage from '../../Reels/ReelsMainPage';
import { useTranslation } from 'react-i18next';
import MessageBox from '../../MessageBox';
import MoreLike from '../../MoreLike';
import usePpvGlobalImageConfig from "../../Api/Rentppv_global_change";
import { RiCheckboxCircleLine, RiCheckboxCircleFill } from "react-icons/ri";
import { useMyContext } from '../../Layout/CommonProvider';
import PageLoader from '../../Loader/PageLoader';
import Comments from '../../Comments';
// import Comments from '../../Others/Comments';

const Videos = ({ videoDetailsData, isLoading, error, settings, profileDetails }) => {
    const { lang, slug } = useParams();
    const { t } = useTranslation();
    const location = useLocation();
    const { navigateTranslateChecker } = useMyContext()
    const user_role = localStorage.getItem("role");
    // const username = localStorage.getItem("username");
    const user_id = localStorage.getItem("user_id");
    // const email = localStorage.getItem("email");
    // const loginTime = localStorage.getItem("loginTime");
    const access_token = localStorage.getItem("access_token");

    const headers = useMemo(() => ({
        "Content-Type": "application/json",
        headers: { "Access-Control-Allow-Origin": "*" }, Authorization: `Bearer ${access_token}`,
    }), [access_token]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [domReload, setDomReload] = useState(false)
    const currentPageURL = window.location.href
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        dispatch(videoDetailsAction(slug, settings, true))
    }, []);


    const handleDOM = () => setDomReload(prevState => !prevState);

    const codeNumber = "1";
    const handleLikeClick1 = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            if (userRole === 9) {
                navigate(navigateTranslateChecker(`/login`));
                return;
            }
            const likeStatus = videoDetailsData?.like === null || videoDetailsData?.like === 0 ? 1 : 0;
            const response = await LikeAction(user_id, userRole, 'web', videoDetailsData?.id, codeNumber, likeStatus);
            dispatch(videoDetailsAction(slug, settings))
            toast.success(t(response?.message));
        } catch (error) {
            console.error(error);
        }
    };
    const handleDislikeClick1 = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            if (userRole === 9) {
                navigate(navigateTranslateChecker(`/login`));
                return;
            }
            const likeStatus = videoDetailsData?.dislike === null || videoDetailsData?.dislike === 0 ? 1 : 0;
            const response = await disLikeAction(user_id, userRole, 'web', videoDetailsData?.id, codeNumber, likeStatus);
            dispatch(videoDetailsAction(slug, settings))
            toast.success(t(response?.message));
        } catch (error) {
            console.error(error);
        }
    };
    const handleClickWishList1 = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            if (userRole === 9) {
                navigate(navigateTranslateChecker(`/login`));
                return;
            }
            const response = await wishListAction(user_id, userRole, 'web', videoDetailsData?.id, codeNumber);
            dispatch(videoDetailsAction(slug, settings))
        } catch (error) {
            console.error(error);
        }
    };

    const handleClickWatchLater1 = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            if (userRole === 9) {
                navigate(navigateTranslateChecker(`/login`));
                return;
            }
            const response = await watchLaterAction(user_id, userRole, 'web', videoDetailsData?.id, codeNumber);
            dispatch(videoDetailsAction(slug, settings))
            toast.success(t(response?.message));
        } catch (error) {
            console.error(error);
        }
    };

    const handleTrailerButton = () => {
        navigate(navigateTranslateChecker(`/trailer/video/${videoDetailsData?.slug}`));
    }

    const onButtonClick = () => {
        const pdfUrl = videoDetailsData?.pdf_files_url;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.target = '_blank';
        link.download = `${videoDetailsData?.slug}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const htmlString = videoDetailsData?.details;
    const theObj = { __html: htmlString };
    const description = t(videoDetailsData?.description);
    const tabs = [
        {
            id: 1,
            tabTitle: "Tab 1",
            title: t("Details"),
            content: <span dangerouslySetInnerHTML={theObj} className='my-2'></span>,
        },
        videoDetailsData?.related_videos?.length > 0
            ? {
                id: 2,
                tabTitle: "Tab 2",
                title: "More Like This",
                content: <MoreLike data={videoDetailsData?.related_videos} segMent="videos" />
            } : {},
        videoDetailsData?.Video_reels_video?.length > 0
            ? {
                id: 3,
                tabTitle: "Tab 3",
                title: "Reels",
                content: <ReelsMainPage videoDetailsData={videoDetailsData} />
            }
            : {},
        {
            id: 4,
            tabTitle: "Tab 4",
            title: t("Comments"),
            content: <Comments />,
        }
    ];


    const [currentTab, setCurrentTab] = useState(tabs[0]?.id);
    const handleTabClick = (tabId) => {
        setCurrentTab(tabId);
    };
    const formatDate1 = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const year = d.getFullYear();
        return `${day}/${month}/${year}`;
    };
    const date = videoDetailsData?.year;
    const formattedDate = formatDate1(date);
    const [time, setTime] = useState('00:00:00');
    const [duration, setDuration] = useState('00:00:00')
    useEffect(() => {
        if (videoDetailsData?.free_duration_time) {
            setTime(videoDetailsData?.free_duration_time);
        }
        if (videoDetailsData?.duration) {
            setDuration(videoDetailsData?.duration)
        }
    }, [videoDetailsData, location]);

    const convertTimeToSeconds = (time) => {
        const [hours, minutes, seconds] = time?.split(':').map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    };
    const totalSeconds = convertTimeToSeconds(time);
    const formatDuration = (duration) => {
        if (!duration) return '';
        const parts = duration.split(':');
        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${hours}H ${formattedMinutes}M`;
    };

    //   geo ip location integration 
    const { currency, currencySymbol, userCurrency, locationResponselocationResponse } = usePpvGlobalImageConfig();
    const [convertedPrice, setConvertedPrice] = useState(null);
    useEffect(() => {
        const fetchConversionRate = async () => {
            if (!userCurrency || !currency?.code || currency?.enable_multi_currency !== 1) return;
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${currency?.code}`);
                const data = response.data;
                const conversionRate = data.rates[userCurrency];
                if (conversionRate) {
                    const convertedPPVPrice = videoDetailsData?.ppv_price * conversionRate;
                    setConvertedPrice(convertedPPVPrice);
                } else {
                    console.error('Conversion rate not found for currency:', userCurrency);
                }
            } catch (error) {
                console.error('Error fetching conversion rate:', error);
            } finally {
            }
        };

        fetchConversionRate();
    }, [userCurrency, currency?.code, currency?.enable_multi_currency, videoDetailsData?.ppv_price]);
    //   geo ip location integration 
    //    Iyzico payment integration 
    const successpage = window.location.href;
    const ppv_priceppv_price = videoDetailsData?.ppv_price
    const [formDataiyzicopayment, setFormDataiyzicopayment] = useState({
        locale: '',
        conversationId: '',
        price: ppv_priceppv_price, // Initialize with an empty value
        paidPrice: ppv_priceppv_price, // Initialize with an empty value
        successpage: successpage, // Initialize with an empty value
        currency: '',
        basketId: '',
        paymentGroup: 'PRODUCT',
        callbackUrl: '',
        enabledInstallments: [2, 3, 6, 9],
        buyer: {
            id: '',
            name: '',
            surname: '',
            gsmNumber: '',
            email: '',
            identityNumber: '',
            lastLoginDate: '',
            registrationDate: '',
            registrationAddress: '',
            ip: '',
            city: '',
            country: '',
            zipCode: ''
        },
        shippingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            country: 'Turkey',
            address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            zipCode: '34742'
        },
        billingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            country: 'Turkey',
            address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            zipCode: '34742'
        },
        basketItems: [
            {
                id: 'BI101',
                name: 'Binocular',
                category1: 'Collectibles',
                category2: 'Accessories',
                itemType: 'PHYSICAL',
                price: ppv_priceppv_price,
            }
        ]
    });

    useEffect(() => {
        let value;
        if (currency?.code === null || currency?.code === "") {
            value = videoDetailsData?.ppv_price !== null ? videoDetailsData?.ppv_price : '';
        } else {
            if (settings?.PPV_Individual_Contents === "0" || settings?.PPV_Individual_Contents === 0) {
                value = convertedPrice !== null ? convertedPrice : '';
            } else {
                value = videoDetailsData?.ContentPriceRate !== null
                    ? videoDetailsData?.ContentPriceRate?.ChangedPrice
                    : convertedPrice !== null
                        ? convertedPrice
                        : '';
            }
        }

        const getCurrentDateTime = () => {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const currentDateTime = getCurrentDateTime();
        const locale = lang === "tr" ? "tr" : "en";
        const conversationId = user_id;
        const currencyfinal = currency?.code;
        const basketId = profileDetails?.Profile_details?.id;
        const gsmNumber = "+" + profileDetails?.Profile_details?.payment_mobile_number;
        const failerpage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/failerpage";
        const successpage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/successpage";
        const pageredirectionslug = `${process.env.REACT_APP_API_PATHss}/` + lang + "/" + "videos" + "/" + videoDetailsData?.slug;
        const callbackUrl = `${process.env.REACT_APP_API_IYZICO_PAYMENT}/callback`;
        const usernames = profileDetails?.Profile_details?.username;
        const last_name = profileDetails?.Profile_details?.last_name;
        const payment_mobile_number = "+" + profileDetails?.Profile_details?.payment_mobile_number;
        const user_ids = user_id;
        const emailemail = profileDetails?.Profile_details?.email;
        const city = locationResponselocationResponse?.city;
        const country = locationResponselocationResponse?.country;
        const zipCode = locationResponselocationResponse?.asn;
        const ip = locationResponselocationResponse?.ip;
        const basketItemsname = videoDetailsData?.title || videoDetailsData?.name;

        setFormDataiyzicopayment(prevState => ({
            ...prevState,
            locale: locale,
            price: ppv_priceppv_price,
            conversationId: conversationId,
            Login_user_id: user_id,
            Login_device_type: "web",
            source_type: 1,
            source_id: videoDetailsData?.id,
            regionname: country,
            countryname: country,
            cityname: city,
            successpage: successpage,
            pageredirectionslug: pageredirectionslug,
            failerpage: failerpage,
            paidPrice: ppv_priceppv_price,
            currency: currencyfinal,
            basketId: basketId,
            callbackUrl: callbackUrl,
            buyer: {
                id: user_ids,
                name: usernames,
                surname: last_name,
                gsmNumber: payment_mobile_number,
                email: emailemail,
                identityNumber: gsmNumber,
                lastLoginDate: currentDateTime,
                registrationDate: currentDateTime,
                registrationAddress: country,
                ip: ip,
                city: city,
                country: country,
                zipCode: zipCode
            },
            shippingAddress: {
                contactName: usernames,
                city: city,
                country: country,
                district: city,
                address: country,
                zipCode: zipCode
            },
            billingAddress: {
                contactName: usernames,
                city: city,
                country: country,
                address: country,
                zipCode: zipCode
            },
            basketItems: [
                {
                    id: user_ids,
                    name: basketItemsname,
                    category1: 'Videos',
                    itemType: 'PHYSICAL',
                    price: ppv_priceppv_price
                }
            ]
        }));

    }, [
        videoDetailsData, currency, settings, convertedPrice,
        lang, locationResponselocationResponse?.asn, locationResponselocationResponse?.city,
        locationResponselocationResponse?.country, locationResponselocationResponse?.ip,
        ppv_priceppv_price, profileDetails?.Profile_details?.email,
        profileDetails?.Profile_details?.id, profileDetails?.Profile_details?.last_name,
        profileDetails?.Profile_details?.payment_mobile_number, profileDetails?.Profile_details?.username,
        user_id
    ]);

    const initializeCheckout = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_IYZICO_PAYMENT}/initialize-checkout`, formDataiyzicopayment);
            if (response.data.status === "success") {
                const paymentPageUrl = response?.data?.paymentPageUrl;
                window.location.href = paymentPageUrl;
            }
            else if (response.data.status === "failure") {
                toast.error(`${response.data.errorMessage}`)
            }
        } catch (error) {
            console.error('Error initializing checkout:', error);
        }
    };


    // useEffect(() => {
    //     let showAlert = true;

    //     const retrievePaymentDetails = async () => {
    //         try {
    //             const response = await axios.get(`http://localhost:7004/retrieve-payment-details`);

    //             if (response.data.status === 'success' && showAlert) {
    //                 try {
    //                     const locationResponse = await axios.get(`${process.env.REACT_APP_API_IP_Location}`);
    //                     const country = locationResponse?.data?.country;
    //                     const country_code = locationResponse?.data?.country_code;
    //                     const city = locationResponse?.data?.city; // Assuming you need city here

    //                     if (city && country) { // Ensure both city and country are defined
    //                         showAlert = false;

    //                         const result = response.data;
    //                         const user_id = localStorage.getItem("user_id");
    //                         const web = "web";
    //                         const videoDetailsDataid = videoDetailsData?.id;

    //                         const formDataiyzico = new FormData();
    //                         formDataiyzico.append("Login_user_id", user_id);
    //                         formDataiyzico.append("Login_device_type", web);
    //                         formDataiyzico.append("source_type", 1);
    //                         formDataiyzico.append("source_id", videoDetailsDataid);
    //                         // formDataiyzico.append("tokenid ", result.token);
    //                         formDataiyzico.append("payment_id", result.paymentId);
    //                         formDataiyzico.append("regionname", country);
    //                         formDataiyzico.append("countryname", country);
    //                         formDataiyzico.append("cityname", city);

    //                         console.log("FormDataiyzico:", Array.from(formDataiyzico.entries())); // Log the FormData content

    //                         const postResponse = await axios.post(
    //                             `${process.env.REACT_APP_Baseurl}/Front-End/iyzico-ppv-verify-store`,
    //                             formDataiyzico
    //                         );

    //                         if (postResponse.data.status_code === 200) {
    //                             toast.success(`Purchased video was Created Successfully`);
    //                         } else {
    //                             toast.error(`Purchased video was Created Failed`);
    //                         }
    //                     } else {
    //                         console.error('City or country is undefined');
    //                     }
    //                 } catch (locationError) {
    //                     console.error('Error retrieving location data:', locationError);
    //                 }
    //             }
    //         } catch (paymentError) {
    //             console.error('Error retrieving payment details:', paymentError);
    //         }
    //     };

    //     retrievePaymentDetails();
    // }, [videoDetailsData]); // Add `videoDetailsData` as a dependency if it's used in the effect


    // Iyzico payment integration 
    // Iyzico payment integration 



    const [paymentGateways, setPaymentGateways] = useState([]);

    const [activeGateway, setActiveGateway] = useState(null); // Initialize activeGateway as null


    const fetchPaymentGateways = useCallback(async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_Baseurl}/Front-End/Payment-Gateway-list`,
                { headers }
            );
            const paymentGateways = response?.data?.Payment_Gateways;
            setPaymentGateways(paymentGateways);
            // Set the active gateway to the first one by default
            setActiveGateway(paymentGateways[0]?.Payment_Gateway_name || null);
        } catch (error) {
            console.error(error);
        }
    }, [headers]);


    const handleGatewayClick = (gatewayName) => {
        setActiveGateway(gatewayName);
    };


    useEffect(() => {
        fetchPaymentGateways();
    }, [fetchPaymentGateways]);

    const renderButton = () => {
        // Extract video details for easier conditions
        const {
            users_free_duration_status,
            users_video_visibility_status,
            users_video_visibility_Rent_button,
            users_video_visibility_becomesubscriber,
            users_video_visibility_register_button,
            slug,
            ppv_price,
            convertedPrice,
        } = videoDetailsData || {};
        const priceLabel = currency?.enable_multi_currency == 1
            ? `Rent Now ${convertedPrice?.Symbol} ${parseFloat(convertedPrice?.ChangedPrice).toFixed(2)}`
            : `Rent Now ${currency?.symbol} ${ppv_price ? parseFloat(ppv_price).toFixed(2) : 'Loading...'}`;

        const renderLinkButton = (text, url) => (
            <Link className='theme-button-bg-color  px-3 py-2 rounded-2 theme-button-text-color d-inline-block' to={navigateTranslateChecker(url)}>
                {t(text)}
            </Link>
        );

        // Free start conditions
        if (!user_role && users_free_duration_status && users_video_visibility_status && !users_video_visibility_Rent_button && !users_video_visibility_becomesubscriber && users_video_visibility_register_button) {
            return renderLinkButton(`Free for start ${totalSeconds} sec`, `/player/video/${slug}`)
        }

        // Continue watching condition
        if (users_video_visibility_status && videoDetailsData?.continue_watching_exists && !users_free_duration_status) {
            return renderLinkButton(`Continue Watching`, `/player/video/${slug}`)
        }

        // Watch now condition
        if (users_video_visibility_status && !videoDetailsData?.continue_watching_exists && !users_free_duration_status) {
            return renderLinkButton(`Watch Now`, `/player/video/${slug}`)
        }

        // Free start with rent button condition
        if (users_video_visibility_status && users_video_visibility_Rent_button && users_free_duration_status) {
            return renderLinkButton(`Free for start ${totalSeconds} sec`, `/player/video/${slug}`)
        }

        // Rent and subscribe condition
        if (!users_video_visibility_status && users_video_visibility_Rent_button && users_video_visibility_becomesubscriber) {
            return (
                <>
                    <button className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block" type='button' data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        {priceLabel}
                    </button>
                    {renderLinkButton(`Subscribe to Entire Library`, `/plan`)}
                </>
            );
        }

        // User role-specific conditions
        if (user_role === 2 && users_free_duration_status && !users_video_visibility_status && users_video_visibility_Rent_button && users_video_visibility_becomesubscriber) {
            return (
                <>
                    <button className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block" type='button' data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        {priceLabel}
                    </button>
                    {renderLinkButton(`Subscribe to Entire Library`, `/plan`)}
                </>
            );
        }

        if ((user_role == 3 && users_video_visibility_status === false && users_video_visibility_Rent_button && !users_video_visibility_becomesubscriber && !users_video_visibility_register_button)) {
            return (
                <>
                    <button className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block" type='button' data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        {priceLabel}
                    </button>

                </>
            );
        }

        // Register now condition
        if (!users_video_visibility_status && users_video_visibility_register_button) {
            return renderLinkButton(`Subscribe to Entire Library`, `/signup`)
        }


        // Subscribe now condition
        if (!users_video_visibility_status && users_video_visibility_becomesubscriber) {
            return renderLinkButton(`Subscribe to Entire Library`, `/plan`)
        }
        return null;
    };


    const staticContent = {
        Stripe: (
            <>
                <div>
                    <button className='btn w-100'>Stripe Pay to the {videoDetailsData?.title ? videoDetailsData.title : videoDetailsData?.name}</button>
                </div>
            </>
        ),
        PayPal: (
            <>
                <div>
                    <button className='btn w-100'>PayPal Pay to the {videoDetailsData?.title ? videoDetailsData.title : videoDetailsData?.name}</button>
                </div>
            </>
        ),
        Razorpay: (
            <>
                <div>
                    <button className='btn w-100'>Razorpay Pay to the {videoDetailsData?.title ? videoDetailsData.title : videoDetailsData?.name}</button>
                </div>
            </>
        ),
        Iyzico: (
            <>
                <div>
                    <button className='btn w-100' onClick={() => initializeCheckout()}>Iyzico Pay to the {videoDetailsData?.title ? videoDetailsData.title : videoDetailsData?.name}</button>
                </div>
            </>
        ),
    };

    return (

        <div className='paddingTopone'>

            {/* { PPV Payment Modal} */}
            <div className="modal fade " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content theme-bg-color">
                        <div className="modal-header">
                            <h5 className="modal-title theme-text-color" id="staticBackdropLabel">Upgrade to <span>{videoDetailsData?.title ? videoDetailsData.title : videoDetailsData?.name}</span> video pack by just paying the difference </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body rent_video_page_ul_li">
                            <div className="row">
                                {/* <div className="col-3 col-md-3 col-lg-3"></div> */}

                                <div className='row col-lg-12'>
                                    <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                                        <img alt={videoDetailsData?.title ? videoDetailsData.title : videoDetailsData?.name} src={videoDetailsData?.Thumbnail} className='w-100 h-100' />
                                    </div>
                                    <div className='col-lg-7 col-md-7 col-sm-7 col-7 rounded-3 border-3'>
                                        <div className='p-2'>
                                            <p className='fw-bold fs-5  theme-text-color'>BUY {videoDetailsData?.title ? videoDetailsData.title : videoDetailsData?.name}</p>

                                            <hr></hr>

                                            <div className='mt-4'>
                                                <div>
                                                    <img alt={videoDetailsData?.title ? videoDetailsData.title : videoDetailsData?.name} src={videoDetailsData?.Player_thumbnail} className='w-50' />
                                                </div>
                                                <div className='mt-3'>
                                                    <ul>
                                                        <li className=' theme-text-color'>
                                                            Available to watch for <span className='fw-bold'>30 Days</span> after renting
                                                        </li>
                                                        <li className=' theme-text-color'>
                                                            Finish within <span className='fw-bold'>48 Hours</span> once started streaming
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <hr></hr>

                                            <div className='mt-2'>
                                                <ul className="nav nav-pills pt-3" id="pills-tab" role="tablist">
                                                    {paymentGateways?.map((gateway, index) => (
                                                        <li
                                                            className="nav-item d-flex align-items-center ms-2 paymenttabheading"
                                                            role="presentation"
                                                            key={index}
                                                        >
                                                            <input
                                                                type="radio"
                                                                className="nav-link"
                                                                id={`pills-${gateway?.Payment_Gateway_name}-tab`}
                                                                name="tab-radio"
                                                                value={gateway?.Payment_Gateway_name}
                                                                checked={activeGateway === gateway?.Payment_Gateway_name}
                                                                onChange={() =>
                                                                    handleGatewayClick(gateway?.Payment_Gateway_name)
                                                                }
                                                            />
                                                            <label
                                                                className="ms-2 theme-text-color"
                                                                htmlFor={`pills-${gateway?.Payment_Gateway_name}-tab`}
                                                            >
                                                                {gateway?.Payment_Gateway_name}
                                                            </label>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                            <div className='mt-2'>
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 offset-md-0">
                                                        <div className="tab-content text-start" id="pills-tabContent">
                                                            {paymentGateways?.map((gateway, index) => (
                                                                <div
                                                                    className={`tab-pane theme-text-color ${activeGateway === gateway?.Payment_Gateway_name
                                                                        ? "show active"
                                                                        : "fade"
                                                                        }`}
                                                                    id={`pills-${gateway?.Payment_Gateway_name}`}
                                                                    role="tabpanel"
                                                                    key={index}
                                                                >
                                                                    <p className="theme-text-color">{staticContent[gateway?.Payment_Gateway_name]}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {error ? <Error404 /> : isLoading ? <PageLoader /> :
                videoDetailsData !== null ?
                    <>
                        <HelmetDetails metaURL={currentPageURL} metaTitle={videoDetailsData?.title ? videoDetailsData.title?.toUpperCase() : videoDetailsData?.name?.toUpperCase()} metaDescription={videoDetailsData?.description?.toString().substring(0, 100)} metaImage={videoDetailsData?.Player_thumbnail} pageTitle={videoDetailsData?.title ? videoDetailsData.title?.toUpperCase() : videoDetailsData?.name?.toUpperCase()} />
                        <div style={{ backgroundImage: `url(${videoDetailsData?.Player_thumbnail})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundAttachment: "fixed" }} className='px-md-5 px-3 py-md-3 py-3 linearShadow w-100 videoDetailsWrapper'>
                            <div className='position-relative'>
                                <Breadcrumb bread1={t("Home")} bread2={t("Videos")} bread3={t(videoDetailsData?.title)} link1="/" link2={navigateTranslateChecker(`/latest-videos`)} slase />
                                <div className='pageWrapper2'>
                                    <div className="col-12 col-lg-10">

                                        {videoDetailsData?.enable_video_title_image === 1 ?
                                            <img src={videoDetailsData?.Video_Title_Thumbnail} alt={videoDetailsData?.slug} className="lazyload d-block videoTitleImg mb-2 object-fit-contain" loading="lazy" />
                                            : <h3 className="midHeading2 mb-3 text-break">{t(videoDetailsData?.title)} {t(videoDetailsData?.name)} </h3>
                                        }

                                        {description ? <MessageBox text={description} className={`descript d-block text-wrap ${expanded ? 'h-auto' : description.length > 200 ? 'hide' : ''}`} /> : null}
                                        {description.length > 200 ? <button className='bg-transparent p-0' onClick={() => setExpanded(!expanded)}><MessageBox text={expanded ? 'Show Less' : 'Show More'} className='theme-text-color descript mt-2 d-block' /></button> : null}

                                        {videoDetailsData?.year || videoDetailsData?.duration ?
                                            <div className='d-flex align-items-center flex-wrap my-4'>
                                                {videoDetailsData?.year ? <label className='smallText1 beforeCircle text-break'>{t(formattedDate)}</label> : ""}
                                                {videoDetailsData?.duration ? <label className='smallText1 ms-4 text-break '>{(formatDuration(duration))}</label> : ""}
                                            </div> : null}

                                        {videoDetailsData?.Language?.length > 0 ?
                                            <div className='d-flex align-items-center flex-wrap '>
                                                {videoDetailsData?.Language?.map((item, index) => (
                                                    <Link to={navigateTranslateChecker(`/languages/${item?.slug}`)} className={`position-relative smallText1 theme-bg-color-secondary rounded-5 px-3 py-1 text-break mb-3 me-4 beforeCircle`}>{t(item?.name)}</Link>
                                                ))}
                                            </div> : null}


                                        <div className='d-flex align-items-center flex-wrap '>
                                            {videoDetailsData?.categories?.length > 0 ? videoDetailsData?.categories?.map((item, index) => (
                                                <Link to={navigateTranslateChecker(`/video-categories/${item?.slug}`)} className={`smallText1 theme-bg-color-secondary rounded-5 px-3 py-1 text-break mb-3  me-4 beforeCircle `}>{t(item?.name)}</Link>
                                            )) : null}
                                            {videoDetailsData?.artists?.length > 0 ? videoDetailsData?.artists?.map((item, index) => (
                                                <Link to={navigateTranslateChecker(`/artists/${item.artist_slug}`)} className={`smallText1 theme-bg-color-secondary rounded-5 px-3 py-1 mb-3 text-break me-4 beforeCircle`}>{t(item?.artist_name)}</Link>
                                            )) : null}
                                        </div>

                                        <div className="d-flex align-items-center gap-4 pt-3 flex-wrap position-relative">
                                            <div className='d-flex flex-wrap gap-3 '>
                                                {renderButton()}
                                                {videoDetailsData?.trailer_videos_url ?
                                                    <button onClick={handleTrailerButton}
                                                        className='theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2'><span>{t("Watch Trailer")}</span>
                                                    </button>
                                                    : null
                                                }
                                            </div>
                                            <div className='d-flex align-items-center gap-3'>
                                                <div className='shareContainer position-relative'>
                                                    <FaShareAlt className="defaultIconStyle" />
                                                    <SocialMediaShareBox URL={currentPageURL} />
                                                </div>
                                                <FiThumbsUp className={`defaultIconStyle ${videoDetailsData?.like === 1 ? "like-active" : ""}`}
                                                    onClick={handleLikeClick1} />
                                                <FiThumbsDown className={`defaultIconStyle ${videoDetailsData?.dislike === 1 ? "dislike-active" : ""}`} onClick={handleDislikeClick1} />
                                                <div className='toolTipParent position-relative'>
                                                    {videoDetailsData?.wishlist !== null ? <RiCheckboxCircleFill className='watchlaterIconStyle' onClick={handleClickWishList1} /> : <RiCheckboxCircleLine className='watchlaterIconStyle' onClick={handleClickWishList1} />}
                                                    <ToolTip bgColor="#000" text={t("Completed")} top="-42px" right="-90px" className="px-3 py-2 rounded-2 position-absolute d-none " width="110px" />
                                                </div>
                                                <div className='toolTipParent position-relative'>
                                                    {videoDetailsData?.WatchLater !== null ? <MdWatchLater className='watchlaterIconStyle' onClick={handleClickWatchLater1} /> : <MdOutlineWatchLater className='watchlaterIconStyle' onClick={handleClickWatchLater1} />}
                                                    <ToolTip bgColor="#000" text={t("Watch Later")} top="-42px" right="-90px" className="px-3 py-2 rounded-2 position-absolute d-none " width="110px" />
                                                </div>

                                                {videoDetailsData?.pdf_files_url ?
                                                    <BsDownload className={`defaultIconStyle`} onClick={onButtonClick} />
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            videoDetailsData?.details ? (
                                <div className='px-md-5 px-3 py-3'>
                                    <ul className="d-flex align-items-center gap-4 flex-wrap m-0 list-unstyled">
                                        {tabs?.map((tab, index) => (
                                            <li
                                                key={index}
                                                className={`${currentTab === tab?.id ? 'highlightTabColor position-relative' : ''} tabHeading mb-3`}
                                                disabled={currentTab === tab.id}
                                                onClick={() => handleTabClick(tab.id)}
                                            >
                                                {t(tab.title)}
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="content">
                                        {tabs?.map((tab, index) => (
                                            currentTab === tab?.id && tab?.content
                                        ))}
                                    </div>
                                </div>
                            ) : null
                        }

                    </>
                    : <div className="py-5 text-center"><BiError style={{ fontSize: "50px" }} /><h3>{t("NO DATA FOUND")}</h3></div>
            }

        </div>

    )
}
const mapStateToProps = state => ({
    videoDetailsData: state.get_videoDetails_Reducer.videoDetailsData,
    profileDetails: state.get_profileDetails_Reducer.profileDetails,
    isLoading: state.get_videoDetails_Reducer.isLoading,
    error: state.get_videoDetails_Reducer.error,
    settings: state.get_setting_Reducer.settings
});
export default connect(mapStateToProps)(Videos)