// External Libraries and Packages
import React, { useEffect, useState } from "react";
import { ToastContainer } from 'react-toastify';
import { connect, useDispatch } from "react-redux";
import { GlobalStyles } from "./GlobalStyles";
import { ThemeProvider } from "styled-components";
import { useLocation, useNavigate } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import "rsuite/dist/rsuite.min.css";
import "react-toastify/dist/ReactToastify.css";

// Internal Styles
import "./App.css";
import "./Styles/CommonAdmin.css"
import "./Styles/root.css"
// Internal Components
import { allHomePageAction, allThumbnailAction, getUserTranslateLanguageAction, headerIndexAction, settingAction, profileDetailsAction, getFieldEnable, siteThemeAction, displayTranslateLanguageAction, footerAction, labelAction } from "./Redux/Actions/apiActions";
import HelmetDetails from "./components/HelmetDetails";
import { useMyContext } from "./components/Layout/CommonProvider.js";
import PageLoader from "./components/Loader/PageLoader.jsx";
import AllRoutes from "./Routes/AllRoutes.jsx";

function App({ languageDetailsData, siteTheme, settings, themeMode, headerSetting, currentTheme, homepage, isLoading }) {
  const userData = localStorage.getItem("access_token")
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const headers = {
    "Content-Type": "application/json",
    headers: { "Access-Control-Allow-Origin": "*" },
    Authorization: "Bearer " + userData,
  };


const dispatch = useDispatch()
  const location = useLocation();
  const navigation = useNavigate()

  const [bodyScrollTop, setBodyScrollTop] = useState(0);
  const [isActionDispatched, setIsActionDispatched] = useState(false);

  const isLaptop = () => {
    const screenWidth = window.innerWidth;
    return screenWidth >= 1024 && screenWidth <= 2000;
  };
  const isMobile = () => {
    const screenWidth = window.innerWidth;
    return screenWidth >= 320 && screenWidth < 1024;
  };

  const { isOnline } = useMyContext()

  // Step 1 
  useEffect(async () => {
    if (isOnline) {
      await dispatch(siteThemeAction())
      await dispatch(labelAction());
    }
  }, [])

  // translate checkout enable
  useEffect(() => {
    const loadTranslations = async () => {
      if (siteTheme?.translate_checkout) {
        if (!languageDetailsData?.Language_Code) {
          try {
            const languageResponse = await dispatch(getUserTranslateLanguageAction(navigation));
            await dispatch(displayTranslateLanguageAction());
          } catch (error) {
            console.error("Failed to load translations:", error);
          }
        }
      }
    };
    loadTranslations();
  }, [siteTheme?.translate_checkout]);


  // When site theme true to render
  useEffect(async () => {
    if (siteTheme && Object.keys(siteTheme).length) {
      await dispatch(settingAction());
    }
  }, [siteTheme])

  // When settings true to render

  useEffect(() => {
    if (settings && Object.keys(settings).length) {
      dispatch(headerIndexAction());
    }
  }, [settings, dispatch])

  useEffect(() => {
    const handleScroll = () => {
      let bodyScrollTop = window.scrollY || document.documentElement.scrollTop;
      setBodyScrollTop(bodyScrollTop);
    };
    if (settings && Object.keys(settings).length) {
      const shouldDispatch = (!user_role && settings.access_free == 1) || user_role;
      window.addEventListener('scroll', handleScroll);
      if (isLaptop() && shouldDispatch && bodyScrollTop > 100 && !isActionDispatched) {
        dispatch(allHomePageAction());
        setIsActionDispatched(true);
      }
      else if (isMobile() && !isActionDispatched) {
        dispatch(allHomePageAction());
        setIsActionDispatched(true);
      }
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [settings, user_role, bodyScrollTop, isActionDispatched]);

  // When header true to render

  useEffect(() => {
    if (headerSetting && Object.keys(headerSetting).length > 0) {
      dispatch(getFieldEnable());
    }
  }, [headerSetting, dispatch]);

  // When homepage  true to render

  useEffect(async () => {
    if (homepage?.length) {
      await dispatch(allThumbnailAction(headers))
      await dispatch(footerAction());
    }
  }, [homepage])


  useEffect(() => {
    if (user_role) {
      dispatch(profileDetailsAction());
    }
  }, [user_role])

  useEffect(async () => {
    await dispatch(footerAction());
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);


  const routeEnable = siteTheme && Object.keys(siteTheme).length;

  return (
    <div className={`${isLoading && 'overflow-hidden heightBody'}`}>
      <HelmetDetails pageTitle={headerSetting?.website_name.toUpperCase()} metaTitle={headerSetting?.website_name.toUpperCase()} metaDescription={headerSetting?.website_description?.toString().substring(0, 100)} metaImage={themeMode?.logoUrl} />
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
      <ThemeProvider theme={themeMode}>
        <GlobalStyles />
        <AllRoutes props={{ currentTheme: currentTheme, TranslateCheckOut: siteTheme, lang: languageDetailsData?.Language_Code || "en" }} />
      </ThemeProvider>
    </div>
  );
}
const mapStateToProps = state => ({
  settings: state.get_setting_Reducer.settings,
  themeMode: state.get_headerIndex_Reducer.themeMode,
  headerSetting: state.get_headerIndex_Reducer.headerSetting,
  currentTheme: state.get_siteTheme_Reducer.currentTheme,
  siteTheme: state.get_siteTheme_Reducer.siteTheme,
  isLoading: state.get_siteTheme_Reducer.isLoading,
  homepage: state.get_allHomePage_Reducer.homepage,
  languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,

});
export default connect(mapStateToProps)(App)